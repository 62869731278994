import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBajPio5MxXOuI4cnrONg76Nmb0AwPnUEA",
  authDomain: "watiszine-51dd3.firebaseapp.com",
  projectId: "watiszine-51dd3",
  storageBucket: "watiszine-51dd3.appspot.com",
  messagingSenderId: "539685957344",
  appId: "1:539685957344:web:d44f629a97bc119d8a6a85",
  measurementId: "G-GLYVQP2ZWC"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { storage, db, auth };