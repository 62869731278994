import React from 'react';
import "../../styling/admin.css";
import { Link } from 'react-router-dom';

function AdminPanel() {
  return (
    <div className="admin-panel">
      <h1>Admin Panel</h1>
      <div className="button-container">
        <Link to="/admin/manage-issues" className="admin-button">Manage Issues</Link>
        <Link to="/admin/edit-faqs" className="admin-button">Edit FAQs</Link>
        <Link to="/admin/update-gallery" className="admin-button">Update Event Gallery</Link>
        <Link to="/admin/update-loading" className="admin-button">Update Loading Texts</Link>
      </div>
    </div>
  );
}

export default AdminPanel;
