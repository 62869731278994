import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import "./adminStyling.css";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  orderBy,
  query,
} from "firebase/firestore";

function EditFaqs() {
  const [faqs, setFaqs] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [newAnswer, setNewAnswer] = useState("");
  const [newColor, setNewColor] = useState("");

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const faqsCollection = collection(db, "faq-data");
        const q = query(faqsCollection, orderBy("slNo")); // Sort by slNo
        const faqsSnapshot = await getDocs(q);
        const faqsList = faqsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFaqs(faqsList);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);

  const handleEdit = async (id, newValue) => {
    try {
      const faqRef = doc(db, "faq-data", id);
      await updateDoc(faqRef, newValue);
      const updatedFaqs = faqs.map((faq) =>
        faq.id === id ? { ...faq, ...newValue } : faq
      );
      setFaqs(updatedFaqs);
    } catch (error) {
      console.error("Error updating FAQ:", error);
    }
  };

  const handleAdd = async () => {
    if (newQuestion.trim() && newAnswer.trim() && newColor.trim()) {
      try {
        const faqsCollection = collection(db, "faq-data");
        const faqRef = await addDoc(faqsCollection, {
          question: newQuestion.trim(),
          answer: newAnswer.trim(),
          color: newColor.trim(),
          slNo: faqs.length + 1, // Assign serial number based on current length
        });
        setFaqs([
          ...faqs,
          {
            id: faqRef.id,
            question: newQuestion,
            answer: newAnswer,
            color: newColor,
            slNo: faqs.length + 1,
          },
        ]);
        setNewQuestion("");
        setNewAnswer("");
        setNewColor("");
      } catch (error) {
        console.error("Error adding FAQ:", error);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const faqRef = doc(db, "faq-data", id);
      await deleteDoc(faqRef);
      const updatedFaqs = faqs.filter((faq) => faq.id !== id);
      setFaqs(updatedFaqs);
    } catch (error) {
      console.error("Error deleting FAQ:", error);
    }
  };

  const handleUpdateSerialNumber = async (id, newSlNo) => {
    try {
      const faqRef = doc(db, "faq-data", id);
      await updateDoc(faqRef, { slNo: newSlNo });
      const updatedFaqs = faqs.map((faq) =>
        faq.id === id ? { ...faq, slNo: newSlNo } : faq
      );
      setFaqs(updatedFaqs);
      console.log("updated successfully!");
    } catch (error) {
      console.error("Error updating serial number:", error);
    }
  };

  function goBack() {
    window.location.pathname = "/admin";
  }

  return (
    <div className="updatePage">
      <h1>Edit FAQs</h1>
      <p>Here you can edit FAQs.</p>

      <table>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Question</th>
            <th>Answer</th>
            <th>Color</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {faqs.map((faq, index) => (
            <tr key={faq.id}>
              <td>{faq.slNo}</td>
              <td>
                <input
                  type="text"
                  value={faq.question}
                  onChange={(e) =>
                    handleEdit(faq.id, { question: e.target.value })
                  }
                />
              </td>
              <td>
                <textarea
                  value={faq.answer}
                  onChange={(e) =>
                    handleEdit(faq.id, { answer: e.target.value })
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={faq.color}
                  onChange={(e) =>
                    handleEdit(faq.id, { color: e.target.value })
                  }
                />
              </td>
              <td>
                <button onClick={() => handleDelete(faq.id)}>Delete</button>
                <button onClick={() => handleUpdateSerialNumber(faq.id, faq.slNo)}>Update</button>
              </td>
            </tr>
          ))}
          <tr>
            <td>{faqs.length + 1}</td>
            <td>
              <input
                type="text"
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                placeholder="Enter new question"
              />
            </td>
            <td>
              <textarea
                value={newAnswer}
                onChange={(e) => setNewAnswer(e.target.value)}
                placeholder="Enter new answer"
              />
            </td>
            <td>
              <input
                type="text"
                value={newColor}
                onChange={(e) => setNewColor(e.target.value)}
                placeholder="Enter color"
              />
            </td>
            <td>
              <button onClick={handleAdd}>Add FAQ</button>
            </td>
          </tr>
        </tbody>
      </table>
      <button className="back" onClick={goBack}>
        Go back to Admin
      </button>
    </div>
  );
}

export default EditFaqs;
