import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase'; // Adjust the path to your Firebase initialization file
import "../styling/faq.css";
import homeBackground from "../Images/2ndhomebkground.png";
import MINUS from "../Images/icons8-close-50.png";
import PLUS from "../Images/icons8-plus-48.png";

export default function Faq() {
    const [faqData, setFaqData] = useState([]);
    const [expandedIndex, setExpandedIndex] = useState(null);

    useEffect(() => {
        const fetchFaqData = async () => {
            try {
                const faqsCollection = collection(db, 'faq-data');
                const faqsSnapshot = await getDocs(faqsCollection);
                let faqsList = faqsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                // Sort faqsList by slNo
                faqsList.sort((a, b) => a.slNo - b.slNo);
                setFaqData(faqsList);
            } catch (error) {
                console.error('Error fetching FAQs:', error);
            }
        };

        fetchFaqData();
    }, []);

    const toggleFAQ = (index) => {
        if (expandedIndex === index) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(index);
        }
    };

    return (
        <>
            <div className="bkgground"
                style={{
                    backgroundImage: `url(${homeBackground})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '80vh',
                }}
            >
                <div className="back2">
                    <div className="qa-form">
                        {faqData.map((faq, index) => (
                            <div key={index}>
                                <button className={`faq-btn ${faq.color} ${expandedIndex === index ? 'expanded' : ''}`} onClick={() => toggleFAQ(index)}>
                                    <div className='madimi-one-regular'>{faq.question}</div>
                                    <div>
                                        {expandedIndex === index ? (
                                            <img src={MINUS} alt='-' height={30} width={30} />
                                        ) : (
                                            <img src={PLUS} alt='-' height={30} width={30} />
                                        )}
                                    </div>
                                </button>
                                {expandedIndex === index && (
                                    <ans className={`wix-madefor-display ans-container ${expandedIndex === index ? 'show' : ''}`}>
                                        {faq.answer}  
                                    </ans>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
