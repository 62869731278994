import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { collection, getDocs, increment } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../styling/imgs.css';
import '../styling/past.css';
import homeBackground from '../Images/2ndhomebkground.png';
import Imp from '../Images/impressions.png';
 
export default function Past() {
    const [issues, setIssues] = useState([]);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');
    const [filteredIssues, setFilteredIssues] = useState([]);
    const [sortOrder, setSortOrder] = useState('Newest');
    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;
    
        async function fetchIssues() {
            try {
                const querySnapshot = await getDocs(collection(db, 'past_issues'));
                const issuesData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
    
                if (isMounted) {
                    setIssues(issuesData);
                    setFilteredIssues(issuesData);
                }
            } catch (error) {
                console.error('Error fetching issues:', error);
                setError('Failed to fetch issues. Please try again later.');
            }
        }
    
        fetchIssues();
        return () => { isMounted = false };
    }, []);
    

    useEffect(() => {
        let sortedIssues = [...issues];
        if (sortOrder === 'Newest') {
            sortedIssues.sort((a, b) => b.issueNumber - a.issueNumber);
        } else {
            sortedIssues.sort((a, b) => a.issueNumber - b.issueNumber);
        }

        if (search) {
            const searchResults = sortedIssues.filter(issue =>
                issue.name && issue.issueNumber &&
                (issue.name.toLowerCase().includes(search.toLowerCase()) ||
                    issue.issueNumber.toString().includes(search))
            );
            setFilteredIssues(searchResults);
        } else {
            setFilteredIssues(sortedIssues);
        }
    }, [search, issues, sortOrder]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    async function handleImpressions(issueId) {
        try {
            const issueRef = doc(db, 'past_issues', issueId); // Reference to the document using the unique ID
            await updateDoc(issueRef, {
                count: increment(1) // Increment the 'count' field by 1
            });
            console.log(`Count incremented successfully for issue ${issueId}!`);
        } catch (error) {
            console.error(`Error updating count for issue ${issueId}:`, error);
        }
    }
    
    function handleItemClick(issue) {
        handleImpressions(issue.id); // Pass the unique issue ID here
        navigate(`/Past/Issue/${issue.issueNumber}`, { state: { pdfUrl: issue.pdfUrl } });
    }
    

    return (
        <div className="bigpage" style={{
            backgroundImage: `url(${homeBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: '80vh',
        }}>
            <div className="headline-past">
                Welcome Ziners! Here are our collections issues dating
                back to 2019! In our pages,
                you’ll find the creation of past and present students!
            </div>
            <h2>Search for our editions!</h2>
            <input
                type="text"
                placeholder="Search for an issue..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="search-bar"
            />
            <div className="search-stuff">
                <select className="option-select" onChange={(e) => setSortOrder(e.target.value)}>
                    <option>Newest</option>
                    <option>Oldest</option>
                </select>
                {search ? <button onClick={() => setSearch('')} className="option-select">Clear Search</button> : ''}
            </div>

            <div className="grid">
                <ul className="row">
                    {filteredIssues.map(issue => (
                        <li key={issue.issueNumber}>
                            <div className='column' onClick={() => handleItemClick(issue)}>
                                <img src={issue.coverPage} height={550} width={336} alt={`Issue ${issue.issueNumber}: ${issue.name}`} />
                            </div>
                            <div className="ViewBoxCounter">
                                <h1 className="wix-madefor-display">
                                    Issue {issue.issueNumber} : {issue.name}
                                </h1>
                                <div className="counter">
                                    <img src={Imp} alt="Impressions" className="imp-image" />
                                    <h3 className="imp-text">{issue.count}</h3>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
