import "../styling/home.css";
import Logo from "../Images/logo.webp";
import homeBackground from '../Images/homebkgrnd.jpg';
import STARS from "../Images/sitare.png";
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { useState, useEffect } from "react";
import '@react-pdf-viewer/core/lib/styles/index.css';
import { storage, db } from "./firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';

<style>
@import url('https://fonts.googleapis.com/css2?family=Nerko+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nerko+One&family=Wix+Madefor+Display:wght@400..800&display=swap');
</style>

export default function Home() {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLatestIssue = async () => {
      try {
        // Create a query to fetch the document with the highest issueNumber
        const issuesCollection = collection(db, 'past_issues');
        const latestIssueQuery = query(issuesCollection, orderBy('issueNumber', 'desc'), limit(1));
        const issueSnapshot = await getDocs(latestIssueQuery);

        if (!issueSnapshot.empty) {
          // Get the PDF URL from the latest issue
          const latestIssue = issueSnapshot.docs[0].data();
          const pdfRef = ref(storage, latestIssue.pdfUrl);

          const pdfUrl = await getDownloadURL(pdfRef);
          setUrl(pdfUrl);
        } else {
          console.warn('No issues found in the database');
        }
      } catch (error) {
        console.error('Error fetching the latest issue:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLatestIssue();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="containerez"
      style={{
        backgroundImage: `url(${homeBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '80vh',
      }}
    >
      <div className="sub-containerez">
        <div className="lefty-content">
          <div className="centralized">
            <div className="leftimage">
              <img src={STARS} alt="stars" height={70} width={70} />
            </div>
            <div className="nerko-one-welcometowatiszine">Welcome to WATisZINE?</div>
          </div>
          <div className="wix-madefor-display">
            A club dedicated to showcasing the quirky, cool and creative things that University of Waterloo students do! By Students, for Students. Look out for our monthly issues!
          </div>
          <img src={Logo} height={200} width={200} alt="landing logo" />
        </div>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div className="righty-content">
            {loading ? (
              <div>Loading PDF...</div>
            ) : (
              <Viewer
                fileUrl={url}
                defaultScale={1.0}
                scrollMode="vertical"
              />
            )}
          </div>
        </Worker>
      </div>
    </div>
  );
}
