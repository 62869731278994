import React, { useState, useEffect } from "react";
import { db } from './firebase'; // Ensure this path is correct
import { collection, getDocs } from 'firebase/firestore';
import "react-slideshow-image/dist/styles.css";
import "../styling/imgs.css";
import homeBackground from "../Images/2ndhomebkground.png";
import Rightarrow from "../Images/right-arrow.png";

function About() {
  const [events, setEvents] = useState([]);
  const [currentIndexes, setCurrentIndexes] = useState({});

  useEffect(() => {
    const fetchEvents = async () => {
      const querySnapshot = await getDocs(collection(db, "events"));
      const eventsData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setEvents(eventsData);

      // Initialize currentIndexes with zeros for each event
      const initialIndexes = {};
      eventsData.forEach(event => {
        initialIndexes[event.id] = 0;
      });
      setCurrentIndexes(initialIndexes);
    };

    fetchEvents();
  }, []);

  const handleNextImage = (eventId) => {
    setCurrentIndexes(prevIndexes => {
      const nextIndex = prevIndexes[eventId] === events.find(event => event.id === eventId).pictures.length - 1 ? 0 : prevIndexes[eventId] + 1;
      return { ...prevIndexes, [eventId]: nextIndex };
    });
  };

  return (
    <div className="bkground" style={{
      backgroundImage: `url(${homeBackground})`,
      backgroundSize: 'cover', 
      backgroundPosition: 'center',
      minHeight: '80vh',
    }}>
      <h1 className="anstowat madimi-one-smol">Here is our Club Event Gallery!</h1>

      <div className="events-container">
        <div className="images--row">
          {events.map(event => (
            <ul key={event.id}>
              <div className="sidebyside">
                <li>
                  <img src={event.pictures[currentIndexes[event.id]]} className="eachimgspecific" alt={`${event.eventName} ${currentIndexes[event.id]}`} />
                </li>
                <button onClick={() => handleNextImage(event.id)} className="rightarrow">
                  <img src={Rightarrow} alt="right"/>
                </button>
              </div>
              <h2 className="sidebyside wix-madefor-display">{event.eventName}</h2>
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
}

export default About;
