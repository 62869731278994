import styles from "../styling/Teams.module.css";
import Art from "./Join Us Posters/Art Team.jpg";
import Team from "./Join Us Posters/Layout Team.jpg";
import Layout from "./Join Us Posters/Layout.jpg";
import Submit from "./Join Us Posters/Submission.jpg";
import Marketing from "./Join Us Posters/Marketing.jpg";

const teams = [
  { img: Art, alt: "art", label: "Art team", style: styles.artTeam },
  { img: Team, alt: "team", label: "Event team", style: styles.eventTeam },
  { img: Layout, alt: "layout", label: "Layout team", style: styles.layoutTeam },
  { img: Submit, alt: "submit", label: "Submission team", style: styles.submissionTeam },
  { img: Marketing, alt: "marketing", label: "Marketing team", style: styles.marketingTeam }
];

const TeamCard = ({ img, alt, label, style }) => (
  <div className={styles.teamCard}>
    <img src={img} alt={alt} height={600} width={450} />
    <button className={`${styles.teamButton} ${style}`}>{label}</button>
  </div>
);

export default function Teams() {
  return (
    <div className={styles.TeamsBackground}>
      <div className={styles.back3}>
        <div className={`${styles.qn} madimi-one-smol`}>Join Our Teams</div>
        <div className={styles.bubblyCloudButton}>
          <a href="https://discord.com/invite/hRHvCvxfhJ">
            <div>DISCORD</div>
          </a>
        </div>
        <div className={styles.imagesGrid}>
          {teams.map((team, index) => (
            <TeamCard key={index} {...team} />
          ))}
        </div>
      </div>
    </div>
  );
}
