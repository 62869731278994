import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "./Images/Logo.png";
import LoadingScreen from "./loading";
import "./styles.css"; // Assuming you have a separate CSS file for your styles

export default function Navbar() {
    const [activeTab, setActiveTab] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleTabClick = (tabName) => {
        setIsLoading(true);
        setActiveTab(tabName);
        setIsDropdownOpen(false);
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <nav className="nav nerko-one-welcometowatiszine">
            <ul className="logo-container">
                <li className="nerko-one-small">
                    <Link to="/" className={activeTab === "/" ? "active" : ""} onClick={() => handleTabClick("/")}>
                        <img src={Logo} alt="logo" height={100} width={100} />
                    </Link>
                </li>
            </ul>
            {isMobile && (
                <button className="dropdown-toggle" onClick={toggleDropdown}>
                    ☰
                </button>
            )}
            <ul className={`nav-items ${isMobile && isDropdownOpen ? "dropdown-open" : ""}`}>
                <li className="nerko-one-small-reg">
                    <Link to="/past" className={activeTab === "past" ? "active" : ""} onClick={() => handleTabClick("past")}>
                        Issues
                    </Link>
                </li>
                <li className="nerko-one-small-reg">
                    <Link to="/about" className={activeTab === "about" ? "active" : ""} onClick={() => handleTabClick("about")}>
                        Event gallery
                    </Link>
                </li>
                <li className="nerko-one-small-reg">
                    <Link to="/submissions" className={activeTab === "submissions" ? "active" : ""} onClick={() => handleTabClick("submissions")}>
                        Submissions
                    </Link>
                </li>
                <li className="nerko-one-small-reg">
                    <Link to="/faq" className={activeTab === "faq" ? "active" : ""} onClick={() => handleTabClick("faq")}>
                        FAQs
                    </Link>
                </li>
                <li className="nerko-one-small-reg">
                    <Link to="/teams" className={activeTab === "teams" ? "active" : ""} onClick={() => handleTabClick("teams")}>
                        Join Us!
                    </Link>
                </li>
                <li className="nerko-one-small-reg">
                    <Link to="/admin" className={activeTab === "admin" ? "active" : ""} onClick={() => handleTabClick("admin")}>
                        Admin
                    </Link>
                </li>
            </ul>
            {isLoading && <LoadingScreen />}
        </nav>
    );
}
