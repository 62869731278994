import React from 'react'
import homeBackground from '../Images/homebkgrnd.jpg';

function lost() {
  return (
        <div className="containerez"
      style={{
        backgroundImage: `url(${homeBackground})`,
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        minHeight: '80vh',
      }}
    >
        <div className='lost'>
        Seems like you got lost! Navigate to go back :)
        </div>
    </div>
  )
}

export default lost