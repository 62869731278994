import { useLocation, useParams } from 'react-router-dom';
import './pdfs.css';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import React, { useState } from 'react';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

export default function Issue() {
    const { state } = useLocation();
    const { pdfUrl } = state || {};

    const zoomPluginInstance = zoomPlugin();

    return (
        <div className="backg">
            <div>
                Here is the PDF for this issue! Enjoy your read!
            </div>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <div className='pdfwindow'>
                    <Viewer
                        fileUrl={pdfUrl}
                        defaultScale={1.0}
                        scrollMode="vertical"
                        plugins={[zoomPluginInstance]}
                    />
                </div>
            </Worker>
            <div>
                Let us know your feedback on this read!
            </div>
        </div>
    );
}
