import React, { useState, useEffect } from 'react';
import { db, auth, storage } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import "./adminStyling.css";

function ManageIssues() {
  const [issues, setIssues] = useState([]);
  const [newIssue, setNewIssue] = useState({ name: '', pdfUrl: '', coverPage: '', count: 0 });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // New state to track upload status

  useEffect(() => {
    const fetchIssues = async () => {
      if (isAuthenticated) {
        const issuesCollection = collection(db, 'past_issues');
        const issueSnapshot = await getDocs(issuesCollection);
        const issuesList = issueSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Sort issues by issueNumber
        issuesList.sort((a, b) => a.issueNumber - b.issueNumber);

        setIssues(issuesList);
      }
    };

    fetchIssues();
  }, [isAuthenticated]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const updatedIssues = [...issues];
    updatedIssues[index][name] = value;
    setIssues(updatedIssues);
  };

  const handleAddIssue = async () => {
    if (newIssue.name.trim() || newIssue.pdfUrl.trim() || newIssue.coverPage.trim() || newIssue.count > 0) {
      try {
        const highestIssueNumber = issues.reduce((max, issue) => Math.max(issue.issueNumber, max), 0);
        const newIssueWithNumber = { ...newIssue, issueNumber: highestIssueNumber + 1 };
        const docRef = await addDoc(collection(db, 'past_issues'), newIssueWithNumber);

        setIssues([...issues, { id: docRef.id, ...newIssueWithNumber }]);
        setNewIssue({ name: '', pdfUrl: '', coverPage: '', count: 0, issueNumber: 0 });
      } catch (error) {
        console.error('Error adding issue:', error);
      }
    } else {
      console.warn('At least one field must be filled');
    }
  };

  const handleDeleteIssue = async (id) => {
    await deleteDoc(doc(db, 'past_issues', id));
    const updatedIssues = issues.filter(issue => issue.id !== id);
    setIssues(updatedIssues);
  };

  const handleUpdateIssue = async (index) => {
    const issue = issues[index];
    const issueDocRef = doc(db, 'past_issues', issue.id);
    await updateDoc(issueDocRef, issue);
    alert("updated successfully!");
  };

  const handlePdfUpload = async (event, index, isNew = false) => {
    const file = event.target.files[0];
    const issueName = isNew ? newIssue.name : issues[index].name;
    if (!issueName) {
      alert('Please enter a name for the issue first');
      return;
    }
    setIsUploading(true); // Set uploading to true
    const storageRef = ref(storage, `past_files/${issueName}_pdf_${file.name}`);
    try {
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      if (isNew) {
        setNewIssue({ ...newIssue, pdfUrl: downloadURL });
      } else {
        const updatedIssues = [...issues];
        updatedIssues[index].pdfUrl = downloadURL;
        setIssues(updatedIssues);
        await handleUpdateIssue(index);
      }
    } catch (error) {
      console.error('Error uploading PDF:', error);
    } finally {
      setIsUploading(false); // Set uploading to false when done
    }
  };

  const handleImageUpload = async (event, index, isNew = false) => {
    const file = event.target.files[0];
    const issueName = isNew ? newIssue.name : issues[index].name;
    if (!issueName) {
      alert('Please enter a name for the issue first');
      return;
    }
    setIsUploading(true); // Set uploading to true
    const storageRef = ref(storage, `past_files/${issueName}_cover_${file.name}`);
    try {
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      if (isNew) {
        setNewIssue({ ...newIssue, coverPage: downloadURL });
      } else {
        const updatedIssues = [...issues];
        updatedIssues[index].coverPage = downloadURL;
        setIssues(updatedIssues);
        await handleUpdateIssue(index);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsUploading(false); // Set uploading to false when done
    }
  };

  if (!isAuthenticated) {
    return <div>Please login to manage issues</div>;
  }

  return (
    <div className='updatePage'>
      <h1>Manage Issues</h1>
      <p>Here you can manage issues.</p>
      <table>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Name of Issue</th>
            <th>PDF URL</th>
            <th>Cover Page</th>
            <th>Count</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {issues.map((issue, index) => (
            <tr key={issue.id}>
              <td>{issue.issueNumber}</td> {/* Display issueNumber as Serial Number */}
              <td>
                <input
                  type="text"
                  name="name"
                  value={issue.name}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="pdfUrl"
                  value={issue.pdfUrl}
                  onChange={(e) => handleInputChange(e, index)}
                  disabled
                />
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => handlePdfUpload(e, index)}
                />
              </td>
              <td>
                {issue.coverPage && <img src={issue.coverPage} alt={`Issue ${issue.issueNumber}`} height="100" />}
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, index)}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="count"
                  value={issue.count}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </td>
              <td>
                <button onClick={() => handleDeleteIssue(issue.id)}>Delete</button>
                <button onClick={() => handleUpdateIssue(index)}>Update</button>
              </td>
            </tr>
          ))}
          <tr>
            <td>{issues.length + 1}</td>
            <td>
              <input
                type="text"
                name="name"
                value={newIssue.name}
                onChange={(e) => setNewIssue({ ...newIssue, name: e.target.value })}
                placeholder="Enter name of issue"
              />
            </td>
            <td>
              <input
                type="text"
                name="pdfUrl"
                value={newIssue.pdfUrl}
                onChange={(e) => setNewIssue({ ...newIssue, pdfUrl: e.target.value })}
                placeholder="Enter PDF URL"
                disabled
              />
              <input
                type="file"
                accept="application/pdf"
                onChange={(e) => handlePdfUpload(e, null, true)}
              />
            </td>
            <td>
              {newIssue.coverPage && <img src={newIssue.coverPage} alt="New Issue Cover" height="100" />}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, null, true)}
              />
            </td>
            <td>
              <input
                type="number"
                name="count"
                value={newIssue.count}
                onChange={(e) => setNewIssue({ ...newIssue, count: Number(e.target.value) })}
                placeholder="Enter count"
              />
            </td>
            <td>
              <button onClick={handleAddIssue} disabled={isUploading}>Add Issue</button> {/* Disable button if uploading */}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ManageIssues;
