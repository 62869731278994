import React from 'react';
import Navbar from './Navbar';
import "./styles.css";
import About from "./pages/About";
import Teams from "./pages/teams";
import Home from "./pages/Home";
import Faq from "./pages/Faq"; 
import Submissions from "./pages/Submissions";
import Past from './pages/Past';
import Footer from './Footer';
import Admin from "./pages/Admin/admin";
import AdminLoading from "./pages/Admin/updateLoading";
import { Route, Routes } from "react-router-dom";
import Issue from './pages/past_pages/issue';
import Lost from './pages/lost';
import Login from './pages/Admin/login';

// For the admin:
import AdminPanel from './pages/Admin/admin';
import ManageIssues from './pages/Admin/ManageIssues';
import EditFaqs from './pages/Admin/EditFaqs';
import UpdateGallery from './pages/Admin/UpdateGallery';
import UpdateLoading from './pages/Admin/updateLoading';
import PrivateRoute from './PrivateRoute'; // Import PrivateRoute
import { ProvideAuth } from './auth'; // Import ProvideAuth

function App() {
  return (
    <ProvideAuth>
      <Navbar />
      <div> 
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/About' element={<About />} />
          <Route path='/Faq' element={<Faq />} />
          <Route path='/Submissions' element={<Submissions />} />
          <Route path='/Past' element={<Past />} />
          <Route path='/Teams' element={<Teams />} />
          <Route path='/login' element={<Login />} />
          <Route path='/admin' element={<PrivateRoute><Admin /></PrivateRoute>} />
          <Route path='/loadingupdate' element={<PrivateRoute><AdminLoading /></PrivateRoute>} />
          <Route path='/Past/Issue/:issueNumber' element={<Issue />} />
          {/* For admin from here */}
          <Route path="/admin" element={<PrivateRoute><AdminPanel /></PrivateRoute>} />
          <Route path="/admin/manage-issues" element={<PrivateRoute><ManageIssues /></PrivateRoute>} />
          <Route path="/admin/edit-faqs" element={<PrivateRoute><EditFaqs /></PrivateRoute>} />
          <Route path="/admin/update-gallery" element={<PrivateRoute><UpdateGallery /></PrivateRoute>} />
          <Route path="/admin/update-loading" element={<PrivateRoute><UpdateLoading /></PrivateRoute>} />
          <Route path='*' element={<Lost />} />
        </Routes>
      </div>
      <Footer />
    </ProvideAuth>
  );
}

export default App;
