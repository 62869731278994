import React, { useState, useEffect } from 'react';
import './adminStyling.css';
import { db, storage } from '../firebase';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

function UpdateGallery() {
  const [events, setEvents] = useState([]);
  const [newEventName, setNewEventName] = useState('');
  const [newPictureFile, setNewPictureFile] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      const querySnapshot = await getDocs(collection(db, "events"));
      const eventsData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setEvents(eventsData);
    };

    fetchEvents();
  }, []);

  const handleAddEvent = async () => {
    const newEvent = { eventName: newEventName, pictures: [] };
    const docRef = await addDoc(collection(db, "events"), newEvent);
    setEvents([...events, { ...newEvent, id: docRef.id }]);
    setNewEventName('');
  };

  const handleAddPicture = async (eventIndex) => {
    if (!newPictureFile) return;
    const event = events[eventIndex];
    const storageRef = ref(storage, `events/${event.id}/${newPictureFile.name}`);
    await uploadBytes(storageRef, newPictureFile);
    const pictureUrl = await getDownloadURL(storageRef);
    const updatedPictures = [...event.pictures, pictureUrl];
    await updateDoc(doc(db, "events", event.id), { pictures: updatedPictures });
    const updatedEvents = events.map((e, i) => (i === eventIndex ? { ...e, pictures: updatedPictures } : e));
    setEvents(updatedEvents);
    setNewPictureFile(null);
  };

  const handleDeletePicture = async (eventIndex, pictureUrl) => {
    const event = events[eventIndex];
    const updatedPictures = event.pictures.filter(p => p !== pictureUrl);
    await updateDoc(doc(db, "events", event.id), { pictures: updatedPictures });
    const updatedEvents = events.map((e, i) => (i === eventIndex ? { ...e, pictures: updatedPictures } : e));
    setEvents(updatedEvents);
  };

  const handleDeleteEvent = async (eventId) => {
    await deleteDoc(doc(db, "events", eventId));
    setEvents(events.filter(event => event.id !== eventId));
  };

  const handleEdit = async (eventIndex, field, value) => {
    const event = events[eventIndex];
    const updatedEvent = { ...event, [field]: value };
    await updateDoc(doc(db, "events", event.id), updatedEvent);
    const updatedEvents = events.map((e, i) => (i === eventIndex ? updatedEvent : e));
    setEvents(updatedEvents);
  };

  return (
    <div className="updatePage">
      <h1>Update Event Gallery</h1>
      <p>Here you can update the event gallery.</p>
      <table>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Event Name</th>
            <th>Pictures</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event, eventIndex) => (
            <tr key={event.id}>
              <td>{eventIndex + 1}</td>
              <td>
                <input
                  type="text"
                  value={event.eventName}
                  onChange={(e) => handleEdit(eventIndex, 'eventName', e.target.value)}
                />
              </td>
              <td>
                {event.pictures.map((picture, pictureIndex) => (
                  <div key={pictureIndex}>
                    <img src={picture} alt="Event" style={{ maxWidth: '100px', marginRight: '10px' }} />
                    <button onClick={() => handleDeletePicture(eventIndex, picture)}>Delete Picture</button>
                  </div>
                ))}
                <input
                  type="file"
                  onChange={(e) => setNewPictureFile(e.target.files[0])}
                  placeholder="Upload picture"
                />
                <button onClick={() => handleAddPicture(eventIndex)}>Add Picture</button>
              </td>
              <td>
                <button onClick={() => handleDeleteEvent(event.id)}>Delete Event</button>
              </td>
            </tr>
          ))}
          <tr>
            <td>{events.length + 1}</td>
            <td>
              <input
                type="text"
                value={newEventName}
                onChange={(e) => setNewEventName(e.target.value)}
                placeholder="Enter new event name"
              />
            </td>
            <td></td>
            <td>
              <button onClick={handleAddEvent}>Add Event</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default UpdateGallery;
