import "../styling/sub.css"
import homeBackground from "../Images/2ndhomebkground.png"

export default function Submissions() {
    return (<> 
        <div style={{
            backgroundImage: `url(${homeBackground})`,
            backgroundSize: 'cover', 
            backgroundPosition: 'center',
            }} 
            className="bkgground">
        <div className="sub">
            <div className="qn madimi-one-smol">HOW TO SUBMIT?</div>
            <div className="qn wix-madefor-display">
                Do you want to submit to our issues? Follow our submissions guideline
                and submit the form below
            </div>
            <div className="qn">
                <button className="bubbly-cloud-butt">
                    <a href="https://docs.google.com/document/d/1ehkU1W8qoMGyUmkSRHBFA0Ld6GOr3vz3HRAaMVRuqyw/edit#heading=h.hhlpdiu3z1h1">
                        Submissions guideline!
                    </a>
                </button>
            </div>
            <div className="qn">
                <button className="bubbly-cloud-butt">
                    <a href="https://docs.google.com/forms/d/1hW5M1LdbgSlbTJkwuDVzdvWrS0L10QeNJwZauIIimcw/viewform?edit_requested=true">
                        Submit here!
                    </a>
                </button>
            </div>
        </div>
        </div>
        </>
    )
}