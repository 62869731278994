import React, { useEffect, useState } from "react";
import './styling/LoadingScreen.css';
import Loadimg from "./Images/logo.webp";
import { db } from "./pages/firebase"; // Import your Firebase configuration
import { collection, getDocs } from "firebase/firestore";

const LoadingScreen = () => {
    const [textMessages, setTextMessages] = useState([]);
    const [randomMessage, setRandomMessage] = useState('');

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'update-loading'));
                const messages = querySnapshot.docs.map(doc => doc.data().text);
                setTextMessages(messages);
                if (messages.length > 0) {
                    const randomIndex = Math.floor(Math.random() * messages.length);
                    setRandomMessage(messages[randomIndex]);
                }
            } catch (error) {
                console.error("Error fetching messages: ", error);
            }
        };

        fetchMessages();
    }, []); // Empty dependency array ensures this effect runs only once on mount

    return (
        <>
            <div className="loading-screen show">
                <div className="pink-bg">
                    <img src={Loadimg} alt="Loading" className="centered-image" />
                    <p className="loading-text visible">
                        {randomMessage}
                    </p>
                </div>
            </div>
        </>
    );
};

export default LoadingScreen;
