import React, { useState, useEffect } from 'react';
import './adminStyling.css';
import { db } from '../firebase'; // Import your Firebase configuration
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";

const initialTextMessages = [
  "As if you had any other choice.",
  "We're testing your patience.",
  "Just count to 10.",
  "Do you come here often?",
  "Don't break your screen yet!",
  "I swear it's almost done.",
  "Get some coffee and come back in ten minutes."
];

function UpdateLoading() {
  const [textMessages, setTextMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  function goBack() {
    window.location.pathname = '/admin';
  }

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'update-loading'));
        const messages = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTextMessages(messages);
      } catch (error) {
        console.error("Error fetching messages: ", error);
      }
    };
    
    fetchMessages();
  }, []);

  const handleEdit = async (id, newText) => {
    try {
      const messageDoc = doc(db, 'update-loading', id);
      await updateDoc(messageDoc, { text: newText });
      setTextMessages(prevMessages => prevMessages.map(msg => (msg.id === id ? { ...msg, text: newText } : msg)));
    } catch (error) {
      console.error("Error updating message: ", error);
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      try {
        const docRef = await addDoc(collection(db, 'update-loading'), { text: newMessage.trim() });
        setTextMessages([...textMessages, { id: docRef.id, text: newMessage.trim() }]);
        setNewMessage('');
      } catch (error) {
        console.error("Error adding message: ", error);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'update-loading', id));
      setTextMessages(prevMessages => prevMessages.filter(msg => msg.id !== id));
    } catch (error) {
      console.error("Error deleting message: ", error);
    }
  };

  return (
    <div className='updatePage'>
      <h1>Update Loading Texts</h1>
      <p>Here you can update loading texts.</p>
      <table>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Text Message</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {textMessages.map((message, index) => (
            <tr key={message.id}>
              <td>{index + 1}</td>
              <td>
                <input
                  type="text"
                  value={message.text}
                  onChange={(e) => handleEdit(message.id, e.target.value)}
                />
              </td>
              <td>
                <button onClick={() => handleDelete(message.id)}>Delete</button>
              </td>
            </tr>
          ))}
          <tr>
            <td>{textMessages.length + 1}</td>
            <td>
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Enter new message"
              />
            </td>
            <td>
              <button onClick={handleAdd}>Add Message</button>
            </td>
          </tr>
        </tbody>
      </table>

      <button className='back' onClick={goBack}>
        Go back to Admin
      </button>
    </div>
  );
}

export { initialTextMessages as textMessages };
export default UpdateLoading;
