import discord_logo from "./Images/discord.svg";
import linktree_logo from "./Images/linktree.png";
import insta_logo from "./Images/IL.png";

export default function Footer() {
    return (
        <div className="footer">
            <div className="left-content">
                <h1>Contact us:</h1>
                <p>Email us at: <a href="mailto:watiszine@gmail.com">watiszine@gmail.com</a></p>
                <p className="pink">You can find us at:</p>
                <p>200 University Ave W SLC 2139, Waterloo, ON N2L 3G1, Canada</p>
            </div>
            <div className="right-content">
                <ul>
                    <li>
                        <a href="https://www.instagram.com/watiszine/">
                            <img src={insta_logo} height={50} width={50} alt="Instagram" />
                        </a>
                    </li>
                    <li>
                        <a href="https://discord.com/invite/hRHvCvxfhJ">
                            <img src={discord_logo} height={50} width={50} alt="Discord" />
                        </a>
                    </li>
                    <li>
                        <a href="https://linktr.ee/watiszine">
                            <img src={linktree_logo} height={50} width={50} alt="Linktree" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}
